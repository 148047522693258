import React, { useState } from 'react';
import classNames from 'classnames';
import { AspectRatioBox, AspectRatioBoxProps, useBreakpoint } from '@moda/om';
import isTouchDevice from 'is-touch-device';
import { useFeatureFlag } from '@moda/portal-stanchions';
import { useList } from '../../hooks/useList';
import { Image } from '../Image';
import { Crossfader } from '../Crossfader';

import './ImageGallery.scss';

export const DEFAULT_ALT_TEXT = 'thumbnail';
export const IMAGE_DURATION_MS = 2000;
export const IMAGE_TRANSITION_MS = 500;

const useCrossfader = (secondaryImagesLength: number, primaryImageLoaded: boolean) => {
  // ideally isTouchDevice() would be enough here, but it's not always true when Lighthouse runs the audit for mobile
  const { matches: isMobile } = useBreakpoint({ lt: 'xs' });

  const enableCrossfader =
    !isTouchDevice() && !isMobile && secondaryImagesLength > 0 && primaryImageLoaded;

  return { enabled: enableCrossfader };
};

type ImageSlide = {
  src: string;
  title?: string | null;
};

type Props = AspectRatioBoxProps & {
  maxHeight: number;
  maxWidth: number;
  images: ImageSlide[];
  active?: boolean;
};

export const ImageGallery: React.FC<Props> = ({
  images,
  active,
  className,
  maxHeight,
  maxWidth,
  ...rest
}) => {
  const featureFlagEnabled = useFeatureFlag('swipeable-plp-images', [
    { default: false },
    { if: true, then: true }
  ]);
  const { matches: isDesktop } = useBreakpoint({ gt: 'sm' });

  const [[primaryImage, ...secondaryImages], { filter: filterImages }] = useList(images);

  const [primaryImageLoaded, setPrimaryImageLoaded] = useState(false);

  const { enabled: enableCrossfader } = useCrossfader(secondaryImages.length, primaryImageLoaded);

  if (!featureFlagEnabled || isDesktop) {
    return (
      <AspectRatioBox
        className={classNames('ImageGallery', className, {
          'ImageGallery--active': active && secondaryImages.length > 0
        })}
        maxHeight={maxHeight}
        maxWidth={maxWidth}
        {...rest}
      >
        {enableCrossfader && (
          <Crossfader
            className="ImageGallery__crossfader"
            duration={IMAGE_DURATION_MS}
            transition={IMAGE_TRANSITION_MS}
            active={active}
          >
            {[...secondaryImages, primaryImage].map(image => (
              <Image
                key={image.src}
                src={image.src}
                alt={image.title || DEFAULT_ALT_TEXT}
                lazyload={false}
                nativeImageSize={{ width: maxWidth, height: maxHeight }}
                width={maxWidth}
                height={maxHeight}
                onError={() => filterImages(otherImage => otherImage.src !== image.src)}
              />
            ))}
          </Crossfader>
        )}

        {primaryImage && (
          <Image
            className="ImageGallery__primary-image"
            src={primaryImage.src}
            alt={primaryImage.title || DEFAULT_ALT_TEXT}
            onLoad={() => setPrimaryImageLoaded(true)}
            nativeImageSize={{ width: maxWidth, height: maxHeight }}
            width={maxWidth}
            height={maxHeight}
            data-testid="ImageGallery__primary-image"
            onError={() => filterImages(image => image.src !== primaryImage.src)}
          />
        )}
      </AspectRatioBox>
    );
  }

  return (
    <AspectRatioBox
      className={classNames('ImageGallery', className, {
        'ImageGallery--active': active && secondaryImages.length > 0
      })}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      {...rest}
    >
      <div className="ImageGallery__carousel">
        <Image
          className="ImageGallery__carousel-image"
          src={primaryImage.src}
          alt={primaryImage.title || DEFAULT_ALT_TEXT}
          onLoad={() => setPrimaryImageLoaded(true)}
          nativeImageSize={{ width: maxWidth, height: maxHeight }}
          width={maxWidth}
          height={maxHeight}
          onError={() => filterImages(image => image.src !== primaryImage.src)}
        />

        {primaryImageLoaded &&
          secondaryImages.map(secondaryImage => (
            <Image
              key={secondaryImage.src}
              className="ImageGallery__carousel-image"
              src={secondaryImage.src}
              alt={secondaryImage.title || DEFAULT_ALT_TEXT}
              nativeImageSize={{ width: maxWidth, height: maxHeight }}
              width={maxWidth}
              height={maxHeight}
              onError={() => filterImages(({ src }) => src !== secondaryImage.src)}
            />
          ))}
      </div>
    </AspectRatioBox>
  );
};
